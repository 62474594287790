export const environment = {
    production: true,
    // apiUrl: 'https://api.efaysto.com/',
    apiUrl: 'https://4wk5l5e5kqpsganahnrdrft2sq0uuskz.lambda-url.us-east-1.on.aws/',
    geoApi: '',
    mapEndpoint: 'https://maps.googleapis.com/maps/api/js',
    mapKey: 'AIzaSyBxbjK-hWHC35NMJGTvvXV5L8BWMVivItg',
    MUMBAI_LINK: 'https://mumbai.polygonscan.com/',
    urlImages: 'https://efaysto-staging-20240708162138110700000001.s3.us-east-1.amazonaws.com/',
  efaystoWallet: '0x739cBaD141A01D07b28135D8ED2F91dd39096882'
  };
  